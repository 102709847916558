.menu {
  position: absolute;
  display: block;
  background-color: var(--theme-modal-background);
  border: 1px solid rgba(0,0,0,0.15);
  border-radius: 0.25rem;
  color: var(--theme-gray-900);
  min-width: 10rem;
  padding: 0.5rem 0;
  z-index: 1000;
}

.menu.hidden {
  visibility: hidden;
  opacity: 0;
}

.menu.visible {
  visibility: visible;
  opacity: 1;
}
