.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  align-items: center;
}

.content.noCharacters {
  flex-direction: column;
}

.streamer {
  margin: 0 auto;
}

.characters, .recentStreams, .timeseries {
  flex-grow: 1;
  max-width: 100%;
  align-self: flex-start;
}

.recentStreams, .timeseries {
  width: 100%;
  flex-basis: 100%;
}

.channelLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.75rem;
}

.channelLink:hover {
  text-decoration: underline;
}

.twitchLogo {
  color: #9146FF;
}

a:hover .twitchLogo {
  color: #772CE8;
}

.header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
}

.streamList {

}

.recentStreams, .timeseries {
  max-width: 100%;
}

.streamsHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
}

.streamsHeader h3 {
  margin-bottom: 0;
}

.streamsHeader .title {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-right: auto;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 0.5rem;
}

.streamsStyleControl {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.streamsStyleButton {
  background: none;
  border: none;
  color: var(--theme-white);
  background-color: var(--theme-gray-400);
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.streamsStyleButton svg {
  display: block;
}

.streamsStyleButton:nth-child(1) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.streamsStyleButton:nth-last-child(1) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.streamsStyleButton.active {
  color: var(--theme-white);
  background-color: var(--theme-gray-600);
}

.streamsStyleButton:not(.active):hover {
  color: var(--theme-white);
  background-color: var(--theme-gray-500);
}

.streamsStyleButton:not(.active):active {
  color: var(--theme-white);
  background-color: var(--theme-gray-700);
}

@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .streamsStyleButton {
    color: var(--theme-gray-400);
    background-color: var(--theme-gray-200);
  }
  :root:not(:global(.light)):not(:global(.dark)) .streamsStyleButton.active {
    color: var(--theme-black);
    background-color: var(--theme-gray-400);
  }
  :root:not(:global(.light)):not(:global(.dark)) .streamsStyleButton:not(.active):hover {
    color: var(--theme-gray-600);
    background-color: var(--theme-gray-300);
  }
  :root:not(:global(.light)):not(:global(.dark)) .streamsStyleButton:not(.active):active {
    color: var(--theme-black);
    background-color: var(--theme-gray-400);
  }
}

:root:global(.dark) .streamsStyleButton {
  color: var(--theme-gray-400);
  background-color: var(--theme-gray-200);
}

:root:global(.dark) .streamsStyleButton.active {
  color: var(--theme-black);
  background-color: var(--theme-gray-400);
}

:root:global(.dark) .streamsStyleButton:not(.active):hover {
  color: var(--theme-gray-600);
  background-color: var(--theme-gray-300);
}

:root:global(.dark) .streamsStyleButton:not(.active):active {
  color: var(--theme-black);
  background-color: var(--theme-gray-400);
}

.segment {
  display: flex;
  align-items: baseline;
  gap: 0.25em;
}

.segment .date,
.segment .live,
.segment .character,
.segment .duration {
  flex-shrink: 0;
  /* white-space: nowrap; */
}

.segment .title {
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  min-width: 10em;
}

.tag {
  /* margin-right: 0.5em; */
}

.characterTag {
  color: var(--const-theme-black);
  background-color: var(--faction-color-dark);
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75em !important;
}

.styleButtons {
  float: right;
}

.characterStyleButton {
  background: none;
  color: inherit;
  border: none;
  opacity: 0.4;
}

.characterStyleButton:not(.active):not(:active):hover {
  opacity: 0.8;
}

.characterStyleButton.active, .characterStyleButton:active {
  opacity: 1;
}

