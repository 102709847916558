.container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.5rem;
  gap: 1rem;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
}

@media (max-width: 767.98px) {
  .container {
    justify-content: space-between;
  }
}

.factionsDropdown {
  order: 0;
  flex-shrink: 0;
}

.search {
  order: 1;
  flex-shrink: 1;
  flex-grow: 1;
  width: auto;
}

.feedbackButton {
  order: 2;
  flex-shrink: 0;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {

}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {

}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {

}


/* X-Small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .container {
    justify-content: space-between;
  }

  .search {
    width: 100%;
  }

  .feedbackButton {
    order: 1;
    flex-shrink: 0;
  }

  .factionsDropdown {
    order: 0;
  }

  .search {
    order: 2;
    width: 100%;
    flex-shrink: 0;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {

 }

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {

}

/* X-Large devices (large desktops, less than 1400px) */
@media (max-width: 1399.98px) {

}
