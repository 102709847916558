.grid {
  --grid-gutter-x: 1.5rem;
  --grid-gutter-y: 1.25rem;
}

.grid .items {
  display: grid;
  grid-gap: var(--grid-gutter-y);
  grid-template-columns: 1fr;
}

@media (min-width: 576px) {
  .grid .items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .grid .items {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .grid .items {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 1200px) {
  .grid .items {
    grid-template-columns: repeat(4, 1fr);
  }
}


@media (min-width: 1400px) {
  .grid .items {
    grid-template-columns: repeat(5, 1fr);
  }
}


.colorSwatch {
  display: inline-block;
  padding: 0.5em 0.5em;
  margin: 0.25em;
  border-radius: 4px;
}

.colorSwatch p {
  margin: 0;
  width: 4em;
}

.card, .picker {
  margin-bottom: 2rem;
}

.info {
  margin-bottom: 1rem;
}

.info h4 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
