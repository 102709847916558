.container {
  position: relative;
  margin: 0 calc(50% - 50vw);
}

.streamContainer {
  position: absolute;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  height: 100%;
}

.paused {
  opacity: 0.8;
  filter: grayscale(0.2);
}

.paused .cardContent {
  filter: grayscale(0.7);
}

.cardContent {
  position: relative;
  background-size: contain;
}

.player {
  position: absolute;
  top: 0;
  left: 0;
}

.playOverlay {
  position: sticky;
  top: 0;
  width: 100%;
}

.playOverlay > div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playButton {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.85);
  transition: ease-in-out .1s;
}

.playButton:hover {
  color: rgba(255, 255, 255, 1);
}

.playButton:active {
  color: rgba(205, 205, 205, 1);
}


.thumbnail {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
  transition: ease-in-out .2s;
}

.thumbnail img {
  width: 100%;
  display: block;
}

.thumbnail:hover {
  transform: scale(1.01) translate3d(0px, -1px, 0px);
  box-shadow: 0 15px 20px rgba(0,0,0,.01), 0 0 5px rgba(0,0,0,.1);
  cursor: pointer;
}

.thumbnail:active {
  transform: scale(1.005);
  box-shadow: 0 5px 10px rgba(0,0,0,.01), 0 0 2px rgba(0,0,0,.1);
  cursor: pointer;
}
