.extension {
  white-space: nowrap;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 1010;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  background-color: var(--navbar-bg-color);
  color: #fff;
  overflow: hidden;
}

.container {
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: space-between;
}

.brand {
  font-size: 1.25rem;
  margin-right: 1rem;
  padding-bottom: 0.3125rem;
  padding-top: 0.3125rem;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
}

.brand:hover {
  color: #fff;
}

.links {
  position: relative;
  flex-basis: 100%;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.extension {
  white-space: nowrap;
  display: none;
  font-size: 0.875rem;
  padding: 0.25rem 0.5rem;
}

@media (min-width: 576px) {
  .navbar {
    overflow: unset;
  }

  .toggler {
    display: none;
  }
  .links {
    display: flex !important;
    justify-content: flex-end;
    flex-basis: auto;
  }
  .nav {
    flex-direction: row !important;
    align-items: center !important;
    flex-basis: unset !important;
  }
  .themeToggle {
    order: unset !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .userDropdown {
    order: unset !important;
    border: none !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .nav > a {
    padding: 0.5rem !important;
  }
}

@media (min-width: 768px) {
  .links {
    justify-content: space-between;
  }

  .extension {
    display: block;
  }
}

.toggler {
  background-color: transparent;
  border: 1px solid hsla(0,0%,100%,.1);
  color: hsla(0,0%,100%,.55);
  border-radius: 0.25rem;
  font-size: 1.25rem;
  line-height: 1;
  padding: 0.25rem 0.75rem;
  transition: box-shadow .15s ease-in-out;
}

.toggler > span {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  height: 1.5em;
  vertical-align: middle;
  width: 1.5em;
  border-color: hsla(0,0%,100%,.1);
  color: hsla(0,0%,100%,.55);
  font-size: 1.25rem;
  line-height: 1;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.55)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.nav {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-basis: 100%;
}

.themeToggle {
  order: 100;
  border-top: hsla(0,0%,100%,.25) 1px solid;
  margin-top: 0.25rem;
  padding-top: 0.25rem;
}

.userDropdown {
  order: 99;
  border-top: hsla(0,0%,100%,.25) 1px solid;
  margin-top: 0.25rem;
  padding: 0.5rem 0 0.25rem 0;
}

.nav > a {
  display: block;
  color: hsla(0,0%,100%,.55);
  padding: 0.5rem 0;
  transition: color .15s ease-in-out;
}

.nav > a:hover {
  color: hsla(0,0%,100%,.75);
}

.nav > a:focus {
  color: hsla(0,0%,100%,.75);
}

.nav > a.active {
  color: #fff;
}
