:root {
  --const-theme-white: #fff;
  --const-theme-black: #000;
  --const-theme-white-rgb: 255,255,255;
  --const-theme-black-rgb: 0,0,0;
  --const-theme-gray-050: #f8f9fa;
  --const-theme-gray-100: #f8f9fa;
  --const-theme-gray-200: #e9ecef;
  --const-theme-gray-300: #dee2e6;
  --const-theme-gray-400: #ced4da;
  --const-theme-gray-500: #adb5bd;
  --const-theme-gray-600: #6c757d;
  --const-theme-gray-700: #495057;
  --const-theme-gray-800: #343a40;
  --const-theme-gray-900: #212529;
  --const-theme-gray-950: #101314;

}

:root {
  --theme-white: var(--const-theme-white);
  --theme-black: var(--const-theme-black);
  --theme-white-rgb: var(--const-theme-white-rgb);
  --theme-black-rgb: var(--const-theme-black-rgb);
  --theme-gray-050: var(--const-theme-gray-050);
  --theme-gray-100: var(--const-theme-gray-100);
  --theme-gray-200: var(--const-theme-gray-200);
  --theme-gray-300: var(--const-theme-gray-300);
  --theme-gray-400: var(--const-theme-gray-400);
  --theme-gray-500: var(--const-theme-gray-500);
  --theme-gray-600: var(--const-theme-gray-600);
  --theme-gray-700: var(--const-theme-gray-700);
  --theme-gray-800: var(--const-theme-gray-800);
  --theme-gray-900: var(--const-theme-gray-900);
  --theme-gray-950: var(--const-theme-gray-950);

  --theme-red: #dc3545;
  --theme-red-100: #f8d7da;
  --theme-red-800: #842029;
  --theme-modal-background: var(--theme-white);

  --theme-primary-color: #0d6efd;
  --theme-primary-color-hover: #0b5ed7;
  --theme-primary-color-active: #0a58ca;
  --theme-primary-color-rgb: 13, 110, 253;

  --theme-secondary-color: var(--theme-gray-600);
  --theme-secondary-color-hover: var(--theme-gray-700);
  --theme-secondary-color-active: var(--theme-gray-700);

  --table-row-border-color: var(--theme-gray-300);
  --table-header-border-color: var(--theme-gray-900);
  --table-row-hover-background-color: rgba(0,0,0,.075);
  --input-field-background-color: var(--theme-white);
  --input-field-border-color: var(--theme-gray-400);
}

@media (prefers-color-scheme: dark) {
  :root:not(.light):not(.dark) {
    --theme-white: var(--const-theme-black);
    --theme-black: var(--const-theme-white);
    --theme-white-rgb: var(--const-theme-black-rgb);
    --theme-black-rgb: var(--const-theme-white-rgb);
    --theme-gray-050: var(--const-theme-gray-950);
    --theme-gray-100: var(--const-theme-gray-900);
    --theme-gray-200: var(--const-theme-gray-800);
    --theme-gray-300: var(--const-theme-gray-700);
    --theme-gray-400: var(--const-theme-gray-600);
    --theme-gray-500: var(--const-theme-gray-500);
    --theme-gray-600: var(--const-theme-gray-400);
    --theme-gray-700: var(--const-theme-gray-300);
    --theme-gray-800: var(--const-theme-gray-200);
    --theme-gray-900: var(--const-theme-gray-100);
    --theme-gray-950: var(--const-theme-gray-050);

    --theme-red: #dc3545;
    --theme-red-100: #842029;
    --theme-red-800: #f5c2c7;
    --theme-modal-background: var(--theme-gray-050);

    --theme-primary-color: #0d6efd;
    --theme-primary-color-hover: #0b5ed7;
    --theme-primary-color-active: #0a58ca;
    --theme-primary-color-rgb: 13, 110, 253;

    --theme-secondary-color: var(--theme-gray-400);
    --theme-secondary-color-hover: var(--theme-gray-500);
    --theme-secondary-color-active: var(--theme-gray-500);

    --table-row-border-color: var(--theme-gray-200);
    --table-header-border-color: var(--theme-gray-500);
    --table-row-hover-background-color: rgba(255,255,255,.095);
    --input-field-background-color: var(--theme-gray-050);
    --input-field-border-color: var(--theme-gray-100);
  }
}

:root.dark {
  --theme-white: var(--const-theme-black);
  --theme-black: var(--const-theme-white);
  --theme-white-rgb: var(--const-theme-black-rgb);
  --theme-black-rgb: var(--const-theme-white-rgb);
  --theme-gray-050: var(--const-theme-gray-950);
  --theme-gray-100: var(--const-theme-gray-900);
  --theme-gray-200: var(--const-theme-gray-800);
  --theme-gray-300: var(--const-theme-gray-700);
  --theme-gray-400: var(--const-theme-gray-600);
  --theme-gray-500: var(--const-theme-gray-500);
  --theme-gray-600: var(--const-theme-gray-400);
  --theme-gray-700: var(--const-theme-gray-300);
  --theme-gray-800: var(--const-theme-gray-200);
  --theme-gray-900: var(--const-theme-gray-100);
  --theme-gray-950: var(--const-theme-gray-050);

  --theme-red: #dc3545;
  --theme-red-100: #842029;
  --theme-red-800: #f5c2c7;
  --theme-modal-background: var(--theme-gray-050);

  --theme-primary-color: #0d6efd;
  --theme-primary-color-hover: #0b5ed7;
  --theme-primary-color-active: #0a58ca;
  --theme-primary-color-rgb: 13, 110, 253;

  --theme-secondary-color: var(--theme-gray-400);
  --theme-secondary-color-hover: var(--theme-gray-500);
  --theme-secondary-color-active: var(--theme-gray-500);

  --table-row-border-color: var(--theme-gray-200);
  --table-header-border-color: var(--theme-gray-500);
  --table-row-hover-background-color: rgba(255,255,255,.095);
  --input-field-background-color: var(--theme-gray-050);
  --input-field-border-color: var(--theme-gray-100);
}

:root {
  --main-bg-color: var(--theme-white);
  --text-color: var(--theme-black);
  --link-color: var(--theme-primary-color);
  --link-color-hover: var(--theme-primary-color-hover);
  --navbar-bg-color: var(--const-theme-gray-900);

  --button-primary-bg-color: var(--theme-primary-color);
  --button-primary-border-color: var(--theme-primary-color);
  --button-primary-content-color: var(--theme-white);
  --button-primary-bg-color-hover: var(--theme-primary-color-hover);
  --button-primary-border-color-hover: var(--theme-primary-color-hover);
  --button-primary-content-color-hover: var(--theme-white);
  --button-primary-bg-color-active: var(--theme-primary-color-active);
  --button-primary-border-color-active: var(--theme-primary-color-active);
  --button-primary-content-color-active: var(--theme-white);

  --button-secondary-bg-color: var(--theme-secondary-color);
  --button-secondary-border-color: var(--theme-secondary-color);
  --button-secondary-content-color: var(--const-theme-white);
  --button-secondary-bg-color-hover: var(--theme-secondary-color-hover);
  --button-secondary-border-color-hover: var(--theme-secondary-color-hover);
  --button-secondary-content-color-hover: var(--const-theme-white);
  --button-secondary-bg-color-active: var(--theme-secondary-color-active);
  --button-secondary-border-color-active: var(--theme-secondary-color-active);
  --button-secondary-content-color-active: var(--const-theme-white);
}


body {
  margin: 0;
  font-family: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
  background-color: var(--main-bg-color);
  min-height: 100vh;

  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(#000, 0);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a, .linkish {
  color: var(--link-color);
  text-decoration: none;
}

a:hover, .linkish:hover {
  color: var(--link-color-hover);
}


img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

th {
  font-weight: bold;
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

[role="button"] {
  cursor: pointer;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}


button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

iframe {
  border: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 0.5rem;
    margin-top: 0;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1.5rem;
  }
}

hr {
    background-color: currentColor;
    border: 0;
    color: inherit;
    margin: 1rem 0;
    opacity: .25;
}

hr:not([size]) {
    height: 1px;
}

.button {
  border-radius: 0.2rem;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 1px solid transparent;
  cursor: pointer;
}

.button-sm {
  padding: 0.25rem 0.5rem;
}

.button.primary {
  background-color: var(--button-primary-bg-color);
  border-color: var(--button-primary-border-color);
  color: var(--button-primary-content-color);
}

.button.primary:hover, .button.primary:focus {
  background-color: var(--button-primary-bg-color-hover);
  border-color: var(--button-primary-border-color-hover);
  color: var(--button-primary-content-color-hover);
}

.button.primary:active {
  background-color: var(--button-primary-bg-color-active);
  border-color: var(--button-primary-border-color-active);
  color: var(--button-primary-content-color-active);
}

.button.primary:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.button.secondary {
  background-color: var(--button-secondary-bg-color);
  border-color: var(--button-secondary-border-color);
  color: var(--button-secondary-content-color);
}

.button.secondary:hover, .button.secondary:focus {
  background-color: var(--button-secondary-bg-color-hover);
  border-color: var(--button-secondary-border-color-hover);
  color: var(--button-secondary-content-color-hover);
}

.button.secondary:active {
  background-color: var(--button-secondary-bg-color-active);
  border-color: var(--button-secondary-border-color-active);
  color: var(--button-secondary-content-color-active);
}

.button.secondary:focus {
  box-shadow: 0 0 0 0.25rem rgb(49 132 253 / 50%);
}

.button:disabled {
  opacity: 0.65;
  pointer-events: none;
}

.content {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 576px) {
  .content {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
}

.inset {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 100%;
}

@media (min-width: 576px) {
  .inset {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

input[type='text'], input[type='search'], textarea {
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  background-color: var(--input-field-background-color);
  border: 1px solid var(--input-field-border-color);
  border-radius: 0.25rem;
  color: var(--theme-gray-900);
  display: block;
  padding: 0.375rem 0.75rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

input[type='text']:focus, input[type='search']:focus, textarea:focus {
  background-color: var(--input-field-background-color);
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  color: var(--theme-gray-900);
  outline: 0;
}

input[type='text']:disabled, input[type='search']:disabled, textarea:disabled {
  background-color: var(--theme-gray-200);
  opacity: 1;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 1em;
  width: 1em;
  opacity: 0.7;
  background-color: currentcolor;
  mask-image: url(data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16"><path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/></svg>);
  mask-size: contain;
}

input[type="checkbox"] {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: 1px solid rgba(var(--theme-black-rgb), 0.25);
  appearance: none;
  border-radius: 0.25em;
}

input[type="checkbox"]:active {
  filter: brightness(90%);
}

input[type="checkbox"]:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--theme-primary-color-rgb), 0.25);
}

input[type="checkbox"]:checked {
  background-color: var(--theme-primary-color);
  border-color: var(--theme-primary-color);
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3E%3C/svg%3E);
}

.visually-hidden, .visually-hidden-focusable:not(:focus):not(:focus-within) {
    clip: rect(0,0,0,0)!important;
    border: 0!important;
    height: 1px!important;
    margin: -1px!important;
    overflow: hidden!important;
    padding: 0!important;
    position: absolute!important;
    white-space: nowrap!important;
    width: 1px!important;
}
