.segmentContainer {
  position: absolute;
  top: 0;
  bottom: 0;
}

.segment {
  width: 100%;
  height: 100%;
  --segment-background-color: var(--theme-gray-300);
  background-color: var(--segment-background-color);
  color: var(--theme-gray-600);
  border-radius: 0.25rem;
  overflow: hidden;
  transition: transform ease-in-out .2s;
}

.segment.live {
  --segment-background-color: var(--theme-gray-800);
  color: var(--theme-white);
}

a:hover .segment, .segmentContainer.hovered .segment {
  --segment-background-color: var(--theme-gray-400);
}

a:hover .segment.live, .segmentContainer.hovered .segment.live {
  --segment-background-color: var(--theme-black);
}

.segment.overlapLeft {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.segment.overlapRight,
.segment.live {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.segment.hidden .segmentContent {
  opacity: 0.6;
}

.segmentContent {
  position: relative;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 0.5rem;
  height: 100%;
}

.thumbnail {
  position: relative;
  border-radius: 0.125rem;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 1/1;
  flex-shrink: 0;
}

.thumbnail img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-shrink: 1;
  overflow: hidden;
}

.compact .info {
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.tags {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.tag.live {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-white);
  background-color: var(--theme-red);
}

.segment p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}

.hiddenOverlay {
  color: var(--const-theme-white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hiddenOverlay svg {
  color: rgba(255,255,255,0.5);
  display: block;
  margin: auto;
  width: auto;
  height: 75%;
  aspect-ratio: 1;
}

.streamPopover {
  position: absolute;
  width: 300px;
  padding: 6px 0;
  transition: opacity 0.15s linear;
}
