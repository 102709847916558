.item {
  display: block;
  background-color: transparent;
  clear: both;
  padding: 0.25rem 1rem;
  white-space: nowrap;
  width: 100%;
  color: var(--theme-black);
}

.item:hover, .item:focus, .item.active {
  background-color: var(--theme-gray-200);
  color: var(--theme-gray-950);
}
