.header {
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.header .text {
  margin-right: auto;
}

.server {
  margin: -0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: background-color linear 0.1s;
  overflow: hidden;
}

.server:hover {
  background-color: var(--theme-gray-200);
}

.server.dragging {
  background-color: var(--theme-gray-050);
}

.server pre {
  margin: 0;
  white-space: normal;
}

.list {
  transition: opacity linear 0.2s;
}

.list.saving {
  opacity: 0.5;
}

.notVisible {
  color: var(--theme-gray-400);
}

.liveCount {
  font-size: .75em;
  color: var(--theme-gray-400);
}

.regex {
  color: var(--theme-gray-600);
}

.notVisible .regex {
  color: var(--theme-gray-300);
}
