.factionDropdownButton {
  color: var(--theme-white);
  font-weight: 400;
}

.factionDropdownButton {
  background-color: var(--faction-color-light);
}

.factionDropdownButton:hover {
  background-color: var(--faction-color-light-hover);
}

.factionDropdownButton:active {
  background-color: var(--faction-color-light-active);
}

.item {
  color: var(--faction-color-light) !important;
}

.item:hover, .item:focus {
  color: var(--faction-color-light-hover) !important;
}

.item:active, .item.active {
  background-color: var(--faction-color-light) !important;
  color: var(--theme-white) !important;
}

.item.active:hover {
  background-color: var(--faction-color-light-hover) !important;
}

.item.noFilter {
  color: var(--theme-black);
}

.item.noFilter:hover {
  color: var(--theme-black);
}

.item.noFilter:active, .item.noFilter.active {
  background-color: var(--theme-gray-200);
  color: var(--theme-white) !important;
}


@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .factionDropdownButton {
    background-color: var(--faction-color-dark);
  }

  :root:not(:global(.light)):not(:global(.dark)) .factionDropdownButton:hover {
    background-color: var(--faction-color-dark-hover);
  }

  :root:not(:global(.light)):not(:global(.dark)) .item {
    color: var(--faction-color-dark) !important;
  }

  :root:not(:global(.light)):not(:global(.dark)) .item:hover, :root:not(:global(.light)):not(:global(.dark)) .item:focus {
    color: var(--faction-color-dark-hover) !important;
  }

  :root:not(:global(.light)):not(:global(.dark)) .item:active, :root:not(:global(.light)):not(:global(.dark)) .item.active {
    background-color: var(--faction-color-dark) !important;
    color: var(--theme-white) !important;
  }

  :root:not(:global(.light)):not(:global(.dark)) .item.active:hover {
    background-color: var(--faction-color-dark-hover) !important;
  }
}

:root:global(.dark) .factionDropdownButton {
  background-color: var(--faction-color-dark);
}

:root:global(.dark) .factionDropdownButton:hover {
  background-color: var(--faction-color-dark-hover);
}

:root:global(.dark) .item {
  color: var(--faction-color-dark) !important;
}

:root:global(.dark) .item:hover, :root:global(.dark) .item:focus {
  color: var(--faction-color-dark-hover) !important;
}

:root:global(.dark) .item:active, :root:global(.dark) .item.active {
  background-color: var(--faction-color-dark) !important;
  color: var(--theme-white) !important;
}

:root:global(.dark) .item.active:hover {
  background-color: var(--faction-color-dark-hover) !important;
}
