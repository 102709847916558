.button {
  background: none;
  border: none;

  display: flex !important;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.2rem;
  color: var(--theme-gray-700);
}

.button:hover {
  /* color: var(--theme-gray-black); */
  background-color: var(--theme-gray-200);
}

.button:active {
  color: var(--theme-gray-black);
  background-color: var(--theme-gray-300);
}
