.header {
  align-items: center;
  border-bottom: 1px solid var(--theme-gray-300);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
}

.body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.body > div {
  margin-bottom: 1rem;
}

.body .labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.body .labelContainer .error {
  font-size: .875em;
  color: var(--theme-red);
}

.body label {
  margin-bottom: 0.5rem;
}

.body textarea {
  min-height: calc(1.5em + 0.75rem + 2px);
}

.body input, .body textarea {
  background-color: var(--theme-modal-background);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  width: 100%;
}

.body input:focus, .body textarea:focus {
  background-color: var(--theme-modal-background);
}

.body input.invalid, .body textarea.invalid {
  background-image: url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E);
  background-position: right calc(0.375em + 0.1875rem) center;
  background-repeat: no-repeat;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  border-color: var(--theme-red);
  padding-right: calc(1.5em + 0.75rem);
}

.body textarea.invalid {
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  padding-right: calc(1.5em + 0.75rem);
}

.body input.invalid:focus, .body textarea.invalid:focus {
  border-color: var(--theme-red);
  box-shadow: 0 0 0 0.25rem rgb(220 53 69 / 25%);
}

.body .description {
  margin-bottom: 1rem;
}

.body .description small {
  font-size: .875em;
}

.twitchUser {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
}

.logOut {
  font-size: 0.875em;
}

.logOut.disabled {
  opacity: 0.8;
  pointer-events: none;
}

.footer {
  align-items: center;
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-top: 1px solid var(--theme-gray-300);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
}

.footer > * {
  margin: 0.25rem;
}

.alert {
  background-color: var(--theme-red-100);
  color: var(--theme-red-800);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 1rem;
  position: relative;
}

.alert a {
  font-weight: 500;
  color: var(--theme-red);
}

.muted {
  --bs-text-opacity: 1;
  color: var(--theme-gray-600);
}

.submitSpinner {
  margin-right: 0.5rem;
}
