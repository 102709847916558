.fade {
  transition: opacity .15s linear;
  opacity: 0;
  pointer-events: none;
}

.fade.show {
  opacity: 1;
  pointer-events: initial;
}

.fade.exiting.onlyFadeIn {
  opacity: 1;
  pointer-events: initial;
}

.slide {
  opacity: 0;
  transform: translateY(-50px);
  transition: transform .3s ease-out, opacity .15s linear;
  pointer-events: none;
}

.slide.show {
  opacity: 1;
  transform: none;
  pointer-events: initial;
}

.collapsing {
  position: relative;
  transition: height 0.35s ease;
}

.collapsing > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.collapse:not(.show) {
  display: none;
}
