.tag {
  border-radius: 4px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875em;
  height: 1.5em;
}
