.tooltip {
  word-wrap: break-word;
  display: block;
  /* font-family: system-ui,-apple-system,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji; */
  font-family: var(--bs-font-sans-serif);
  font-size: .875rem;
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  margin: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  z-index: 1080;
}

.inner {
  background-color: #000;
  border-radius: 0.25rem;
  color: #fff;
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  text-align: center;
}

.arrow {
  display: block;
  height: 0.4rem;
  position: absolute;
  width: 0.8rem;
}

.arrow:before {
    border-color: transparent;
    border-style: solid;
    content: "";
    position: absolute;
}

.tooltip[data-popper-placement^=bottom] .arrow {
  top: 0;
}

.tooltip[data-popper-placement^=bottom] .arrow:before {
    border-bottom-color: #000;
    border-width: 0 0.4rem 0.4rem;
    bottom: -1px;
}

.tooltip[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
