.timelineDate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}

.weekday {
  font-weight: 500;
}

.timelineDate p {
  margin: 0;
  white-space: nowrap;
}

.gapRow {
  width: 100%;
  text-align: center;
  font-style: italic;
  color: var(--theme-gray-600);
}
