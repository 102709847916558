.container {
  display: grid;
  grid-template: 1 / 1;
}

.container > * {
  grid-area: 1 / 1;
  min-width: 0px;
}

.fadeOver {
  z-index: -1;
}
