.hourHeader {
  height: 1.5rem;
  position: relative;
  margin-bottom: 1rem;
}

.hourLabel {
  position: absolute;
  white-space: nowrap;
}

.hourLabel p {
  transform: translateX(-50%);
}

.sidebarRow,
.timelineRow {
  height: 5rem;
}

.sidebarInfoRow,
.timelineInfoRow {
  height: 1.5rem;
}

.compact .sidebarRow,
.compact .timelineRow {
  height: 3.25rem;
}

.sidebarRow.hovered,
.timelineRow.hovered {
  background-color: rgba(var(--theme-black-rgb), 0.025);
}

.sidebarRow,
.timelineRow {
  padding: 0.5rem 0;
}

.sidebarInfoRow > div,
.timelineInfoRow > div,
.sidebarRow > div,
.timelineRow > div {
  position: relative;
  height: 100%;
  width: 100%;
}


.content {
}

.compact {}

.container {
  position: relative;
  display: flex;
  flex-direction: row;
}

.sidebar {
  border-right: 1px solid var(--theme-gray-600);
}

.sidebar.hasHourHeader {
  padding-top: 2.5rem;
}

.sidebarRow {
  margin-left: -0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.timelineContainer {
  position: relative;
  overflow-x: scroll;
  flex-grow: 1;
}

.hourBars {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.hourBar {
  position: absolute;
  top: 0;
  bottom: 0;
  border-right: 1px solid var(--theme-gray-200);
}

.hourBar.midnight {
  border-right-color: var(--theme-gray-400);
}

.hourBar.now {
  border-right-color: var(--theme-red);
}

.rows, .sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  /* gap: 1rem; */
}

@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .sidebarRow.hovered,
  :root:not(:global(.light)):not(:global(.dark)) .timelineRow.hovered {
    background-color: rgba(var(--theme-black-rgb), 0.075);
  }

}

:root:global(.dark) .sidebarRow.hovered,
:root:global(.dark) .timelineRow.hovered {
  background-color: rgba(var(--theme-black-rgb), 0.075);
}
