.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  pointer-events: none;
}

.top,
.bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
}

.top:not(:has(.left)),
.bottom:not(:has(.left)) {
  justify-content: flex-end;
}

.top {
  align-items: flex-start;
}

.bottom {
  align-items: flex-end;
}

.left .section,
.right .section {
  display: flex;
  gap: 0.25rem;
  justify-content: flex-start;
  height: 100%;
  widows: 100%;
  position: relative;
}

.left .section {
  align-items: flex-start;
}

.right .section {
  align-items: flex-end;
}

.top .left .section,
.top .right .section {
  flex-direction: column;
}

.bottom .left .section,
.bottom .right .section {
  flex-direction: column-reverse;
}

.section .tag:first-of-type {
  z-index: 10;
}

.section:not(.hovered) .tag:not(:first-of-type) {
  opacity: 0;
  position: absolute;
}

.top .section:not(.hovered) .tag:not(:first-of-type) {
  top: 0;
}

.bottom .section:not(.hovered) .tag:not(:first-of-type) {
  bottom: 0;
}

.tag {
  pointer-events: all;
  cursor: default;
}

.tag.clickable {
  cursor: pointer;
}

.tag.factionStyled {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-black);
  background-color: var(--faction-color-dark);
}

.tag.factionStyled.clickable:hover {
  background-color: var(--faction-color-dark-hover);
}

.tag.factionStyled.clickable:active {
  background-color: var(--faction-color-dark-active);
}

.tag.plain {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-black);
  background-color: var(--const-theme-white);
}

.tag.factionPill {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-black);
  background-color: var(--faction-color-dark);
  border-radius: 50rem;
}

.tag.factionPill.clickable:hover {
  background-color: var(--faction-color-dark-hover);
}

.tag.factionPill.clickable:active {
  background-color: var(--faction-color-dark-active);
}

.tag.live {
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-white);
  background-color: var(--theme-red);
}

.tag.secondary {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
}

.tag.secondary.error {
  background-color: rgba(95, 0, 0, 0.8);
}
