.tableContainer {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  border-color: var(--table-row-border-color);
}

.table thead {
  vertical-align: bottom;
}

.table th {
  padding: 0.5rem;
  white-space: nowrap;
}

.table tbody {
  border-top: 2px solid var(--table-header-border-color);
}

.table td {
  border-bottom-width: 1px;
  padding: 0.5rem;
  border-color: inherit;
}

.sortableHeader {
  white-space: nowrap;
  cursor: pointer;
}

.sortableHeader:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.25rem;
  width: 16px;
  height: 16px;
}

.sortableHeader.current.asc:after,
.sortableHeader.asc:not(.current):hover:after {
  mask: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='1em' height='1em' fill='currentColor'><path d='M3.5 3.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 12.293V3.5zm4 .5a.5.5 0 0 1 0-1h1a.5.5 0 0 1 0 1h-1zm0 3a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm0 3a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1h-5zM7 12.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5z'></path></svg>");
  background-color: var(--theme-gray-600);
}

.sortableHeader.current.desc:after,
.sortableHeader.desc:not(.current):hover:after {
  mask: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' width='1em' height='1em' fill='currentColor'><path d='M3.5 2.5a.5.5 0 0 0-1 0v8.793l-1.146-1.147a.5.5 0 0 0-.708.708l2 1.999.007.007a.497.497 0 0 0 .7-.006l2-2a.5.5 0 0 0-.707-.708L3.5 11.293V2.5zm3.5 1a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM7.5 6a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1h-3zm0 3a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z'></path></svg>");
  background-color: var(--theme-gray-600);
}

.sortableHeader.current.asc:hover:after,
.sortableHeader.current.desc:hover:after {
  background-color: var(--theme-gray-800);
}

.table:not(.noHover) tr:hover td {
  background-color: var(--table-row-hover-background-color);
}

.pfp {
  display: inline-block;
  vertical-align: bottom;
  margin-right: 0.5rem;
  filter: grayscale(1.0);
  opacity: 0.6;
}

.characterRow:hover .pfp {
  filter: none;
  opacity: 1.0;
}

.channelName, .name, .lastSeen, .totalSeen {
  white-space: nowrap;
}

.channelName a, .name a {
  color: inherit;
}

.name .deceased {
  color: var(--theme-gray-500);
}

.channelName a:hover, .name a:hover {
  color: var(--theme-primary-color-hover);
}

.channelName a:active, .name a:active {
  color: var(--theme-primary-color-active);
}

.liveTag {
  margin: 0.5em;
}

.factionPill {
  margin-right: 0.25em;
}

.factionPill span {
  background-color: var(--faction-color-light);
  border-radius: 50rem;
  color: var(--theme-white);
  display: inline-block;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  padding: 0.35em 0.65em;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.factionPill:hover span {
  background-color: var(--faction-color-light-hover);
}

.factionPill:active span {
  background-color: var(--faction-color-light-active);
}

.contact .obscured {
  position: relative;
}

.contact .obscured span.value {
  transform: translate3d(0, 0, 0);
  filter: blur(6px);
  user-select: none;
  transition: opacity 0.15s linear;
  transition: filter 0.15s linear;
}

.table:not(.noHover) tr:hover .contact .obscured .value,
.table.noHover .contact .obscured:hover .value {
  opacity: 0.5;
}

@media (hover: none) {
  .contact .obscured .value {
    opacity: 0.5;
  }
}

.contact .obscured .showButton {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: var(--theme-primary-color);
}

.contact .obscured .showButton:hover {
  color: var(--theme-primary-color-hover);
}

.contact .obscured .showButton:active {
  color: var(--theme-primary-color-active);
}

.table:not(.noHover) tr:hover .contact .obscured .showButton,
.table.noHover .contact .obscured:hover .showButton {
  opacity: 1.0;
}

@media (hover: none) {
  .contact .obscured .showButton {
    opacity: 1.0;
  }
}

.contact .obscured .showButton svg {
  display: block;
}

@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .factionPill span {
    background-color: var(--faction-color-dark);
  }

  :root:not(:global(.light)):not(:global(.dark)) .factionPill:hover span {
    background-color: var(--faction-color-dark-hover);
  }

  :root:not(:global(.light)):not(:global(.dark)) .factionPill:active span {
    background-color: var(--faction-color-dark-active);
  }
}

:root:global(.dark) .factionPill span {
  background-color: var(--faction-color-dark);
}

:root:global(.dark) .factionPill:hover span {
  background-color: var(--faction-color-dark-hover);
}

:root:global(.dark) .factionPill:active span {
  background-color: var(--faction-color-dark-active);
}
