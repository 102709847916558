.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.online, .offline {
  width: 400px;
}
