.header {
  align-items: center;
  border-bottom: 1px solid var(--theme-gray-300);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
}

.body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.body > div {
  margin-bottom: 1rem;
}

.formCheckbox input[type="checkbox"] {
  display: inline-block;
  margin-right: 0.65rem;
}

.footer {
  align-items: center;
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-top: 1px solid var(--theme-gray-300);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
}

.footer > * {
  margin: 0.25rem;
}
