.grid {
  --grid-gutter-x: 1.5rem;
  --grid-gutter-y: 1.25rem;
}

.grid .items {
  display: grid;
  grid-gap: var(--grid-gutter-y);
  grid-template-columns: [start] repeat(auto-fill, minmax(268px, 1fr)) [end];
}

.spinnerCard {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: start / end;
  margin: 1.5rem;
}
