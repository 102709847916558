.header {
  align-items: center;
  border-bottom: 1px solid var(--theme-gray-300);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  padding: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
  font-weight: 500;
}

.body {
  flex: 1 1 auto;
  padding: 1rem;
  position: relative;
}

.body > div {
  margin-bottom: 1rem;
}

.card {
  width: 20rem;
  margin: 0 auto;
}

.disabled {
  color: var(--theme-gray-600);
}

.tag {
  padding: 0.25em 0.5em;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--const-theme-white);
  background-color: var(--faction-color-light);
}

.characterDropdown {
  width: 100%;
}

.characterDropdownButton {
  text-align: left;
  width: 100%;
}

.serverDropdown {
  width: 100%;
}

.serverDropdownButton {
  text-align: left;
  width: 100%;
}

.body .labelContainer {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.body .labelContainer .error {
  font-size: .875em;
  color: var(--theme-red);
}

.footer {
  align-items: center;
  border-bottom-left-radius: calc(0.3rem - 1px);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-top: 1px solid var(--theme-gray-300);
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0.75rem;
}

.footer > * {
  margin: 0.25rem;
}

.alert {
  background-color: var(--theme-red-100);
  color: var(--theme-red-800);
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 1rem;
  position: relative;
  flex-basis: 100%;
}

.submitSpinner {
  margin-right: 0.5rem;
}
