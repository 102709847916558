.backdrop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  z-index: 1050;

  transition: opacity .15s linear;
  opacity: 0;
}

.backdrop.show {
  opacity: 0.5;
}

.modal {
  display: block;
  height: 100%;
  left: 0;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1055;

  opacity: 0;
  transform: translateY(-50px);
  transition: transform .3s ease-out, opacity .15s linear;
}

.modal.show {
  opacity: 1;
  transform: none;
}

.dialog {
  position: relative;
}

.shake .dialog {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.content {
  background-clip: padding-box;
  background-color: var(--theme-modal-background);
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  outline: 0;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.dialog {
  max-width: 100%;
  --modal-margin: 1.75rem;
  margin: var(--modal-margin) auto;
}

.centered .dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--modal-margin) * 2);
}

.content {

}

@media (min-width: 576px) {
  .dialog {
    max-width: 500px;
    --modal-margin: 1.75rem;
  }
}

@media (min-width: 768px) {
  .dialog {
    max-width: 500px;
    --modal-margin: 1.75rem;
  }
}

@media (min-width: 992px) {
  .dialog {
    max-width: 500px;
    --modal-margin: 1.75rem;
  }
}


/* .backdrop. */

