.card {
}

.thumbnail {
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
  transition: ease-in-out .2s;
  aspect-ratio: 16/9;
  background-color: var(--theme-gray-300);
}

.thumbnail.hasThumbnail {
  background-color: unset;
}

.lastStreamThumbnail {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  transition: ease-in-out .2s;
  filter: grayscale(0.7);
}

.thumbnailBlurOverlay, .thumbnailColorOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.thumbnailBlurOverlay {
  transition: backdrop-filter ease-in-out .2s;
  backdrop-filter: blur(4px);
}

.thumbnailColorOverlay {
  transition: opacity ease-in-out .2s;
  opacity: 0.5;
  background-color: var(--theme-gray-050);
}

.thumbnail:hover .thumbnailBlurOverlay {
  backdrop-filter: none;
}

.thumbnail:hover .thumbnailColorOverlay {
  opacity: 0;
}

.thumbnail.hasThumbnail:hover .lastStreamThumbnail {
  opacity: 1;
  filter: grayscale(0.0);
}

.thumbnail a {
  display: block;
  width: 100%;
  height: 100%;
}

.thumbnail .offline {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: space-evenly;
  text-decoration: none;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  position: absolute;
}

.thumbnail.hasThumbnail:hover .offline {
  opacity: 0;
}

.thumbnail.hasThumbnail .offline {
  transition: ease-in-out .2s;
}

.thumbnail.hasThumbnail:not(:hover) .offline {
}

.thumbnail .offline p {
  margin: 0;
  color: var(--theme-gray-100);
  text-align: center;
}

.thumbnail.hasThumbnail .offline p {
  color: var(--theme-gray-100);
}

.thumbnail.hasThumbnail .offline .lastSeen {
  color: var(--theme-gray-100);
}

.thumbnail .offline p:not(.lastSeen) {
  font-size: 3em;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 1;
}

.thumbnail .offline .lastSeen {
  color: var(--theme-gray-600);
  line-height: 1.25;
}

.thumbnail .offline .lastSeen.spacer {
  visibility: hidden;
}

.thumbnail:hover {
  transform: scale(1.01) translate3d(0px, -1px, 0px);
  box-shadow: 0 15px 20px rgba(0,0,0,.01), 0 0 5px rgba(0,0,0,.1);
  cursor: pointer;
}

.thumbnail:active {
  transform: scale(1.005);
  box-shadow: 0 5px 10px rgba(0,0,0,.01), 0 0 2px rgba(0,0,0,.1);
  cursor: pointer;
}

.tagOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0.5rem;
  transition: filter ease-in-out .2s;
  filter: grayscale(0.7);
}

.thumbnail:hover .tagOverlay {
  filter: none;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  filter: grayscale(0.7);
}

.pfp {
  margin-right: 0.25rem;
}

.text {
  display: flex;
  flex-direction: column;
  line-height: 1.25em;
  min-width: 0;
}

.title p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.title.wrap p {
  white-space: unset;
}

.channel p {
  margin: 0;
}

.channel a {
  text-decoration: none;
  color: var(--faction-color-light);
}

.channel a:hover {
  text-decoration: none;
  color: var(--faction-color-light-hover);
}

.channel a:active {
  color: var(--faction-color-light-active);
}

@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .thumbnail.hasThumbnail:hover {
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
  }

  :root:not(:global(.light)):not(:global(.dark)) .thumbnailColorOverlay {
    background-color: var(--theme-gray-500);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a {
    color: var(--faction-color-dark);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a:hover {
    color: var(--faction-color-dark-hover);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a:active {
    color: var(--faction-color-dark-active);
  }

  :root:not(:global(.light)):not(:global(.dark)) .thumbnail .offline .lastSeen {
    color: var(--theme-gray-050);
  }
}

:root:global(.dark) .thumbnail.hasThumbnail:hover {
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

:root:global(.dark) .thumbnailColorOverlay {
  background-color: var(--theme-gray-500);
}

:root:global(.dark) .channel a {
  color: var(--faction-color-dark);
}

:root:global(.dark) .channel a:hover {
  color: var(--faction-color-dark-hover);
}

:root:global(.dark) .channel a:active {
  color: var(--faction-color-dark-active);
}

:root:global(.dark) .thumbnail .offline .lastSeen {
  color: var(--theme-gray-050);
}
