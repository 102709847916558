.card.container {
  background: var(--theme-gray-100);
  border-radius: 6px;
  box-shadow: 0 15px 20px rgba(0,0,0,.02), 0 0 5px rgba(0,0,0,.2);
  padding-bottom: 0.5rem;
  overflow: hidden;
}

.thumbnail {
  position: relative;
  margin-bottom: 0.5rem;
  aspect-ratio: 16/9;
  background-color: var(--bs-gray-300);
}

.inline .thumbnail {
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 0 0 0 rgba(0,0,0,0), 0 0 0 rgba(0,0,0,0);
  transition: ease-in-out .2s;
}

.thumbnail img {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
}

.thumbnail iframe {
  pointer-events: none;
  position: absolute;
  top: 0;
}

.thumbnail .hidden {
  opacity: 0%;
}

.inline .thumbnail:hover {
  transform: scale(1.01) translate3d(0px, -1px, 0px);
  box-shadow: 0 15px 20px rgba(0,0,0,.01), 0 0 5px rgba(0,0,0,.1);
  cursor: pointer;
}

.inline .thumbnail:active {
  transform: scale(1.005);
  box-shadow: 0 5px 10px rgba(0,0,0,.01), 0 0 2px rgba(0,0,0,.1);
  cursor: pointer;
}

.tagOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0.5rem;
}

.hiddenOverlay {
  color: var(--const-theme-white);
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hiddenOverlay svg {
  color: rgba(255,255,255,0.5);
  display: block;
  margin: auto;
  width: auto;
  height: 50%;
  aspect-ratio: 1;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}

.card .info {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.text {
  display: flex;
  flex-direction: column;
  line-height: 1.25em;
  min-width: 0;
  flex-grow: 1;
}

.title p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}

.title.wrap p {
  white-space: unset;
}

.channel p {
  margin: 0;
}

.channel a {
  text-decoration: none;
  color: var(--faction-color-light);
}

.channel a:hover {
  text-decoration: none;
  color: var(--faction-color-light-hover);
}

.channel a:active {
  color: var(--faction-color-light-active);
}

.editButton {
  display: none;
}

.container:hover .editButton {
  display: block;
}

@media (prefers-color-scheme: dark) {
  :root:not(:global(.light)):not(:global(.dark)) .card.container {
    background: var(--theme-gray-050);
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
  }

  :root:not(:global(.light)):not(:global(.dark)) .card .thumbnail {
    border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
  }

  :root:not(:global(.light)):not(:global(.dark)) .inline .thumbnail, :root:not(:global(.light)):not(:global(.dark)) .inline .thumbnail:hover, :root:not(:global(.light)):not(:global(.dark)) .inline .thumbnail:active {
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
  }

  :root:not(:global(.light)):not(:global(.dark)) .pfsp {
    box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a {
    color: var(--faction-color-dark);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a:hover {
    color: var(--faction-color-dark-hover);
  }

  :root:not(:global(.light)):not(:global(.dark)) .channel a:active {
    color: var(--faction-color-dark-active);
  }
}

:root:global(.dark) .card.container {
  background: var(--theme-gray-050);
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

:root:global(.dark) .card .thumbnail {
  border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
}

:root:global(.dark) .inline .thumbnail, :root:global(.dark) .inline .thumbnail:hover, :root:global(.dark) .inline .thumbnail:active {
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

:root:global(.dark) .pfsp {
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.2);
}

:root:global(.dark) .channel a {
  color: var(--faction-color-dark);
}

:root:global(.dark) .channel a:hover {
  color: var(--faction-color-dark-hover);
}

:root:global(.dark) .channel a:active {
  color: var(--faction-color-dark-active);
}
