.notLive {
  opacity: 0.4;
}

.noMatches {
  text-align: center;
}

.otherMatchesHeader {
  margin-top: 3rem;
  margin-bottom: 1.5rem;
}
